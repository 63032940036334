







import { Component, Vue } from 'vue-property-decorator';
import Layer from '@/components/service/InsLayer.vue';
import { FrontE } from '@/sdk/common/SysConst';
@Component({
  components: {
    Layer
  }
})
export default class App extends Vue {
  get currentlang () {
    return this.$i18n.locale;
  }

//   getQueryVariable (variable) {
//   var query = window.location.search.substring(1);
//   var vars = query.split('&');

//   for (var i = 0; i < vars.length; i++) {
//           var pair = vars[i].split('=');
//           console.log(pair);
//           if (pair[0] === variable) { return pair[1]; }
//   }
//   return null;
// };
  mounted() {
    // let q_lang = this.getQueryVariable('lang') as string;
    //       if (q_lang !== null && q_lang !== undefined) {
    //         this.$i18n.locale = q_lang;
    //         localStorage.setItem('locale', q_lang);
    //         // window.location.href = '?lang=' + q_lang;
    //       }

          // console.log(q_lang, '第二个lang');
    Vue.prototype.$ShowLayer();
    setTimeout(() => {
      Vue.prototype.$HiddenLayer();
    }, 2000);
  }
  beforeUpdate() {
    if (this.$route.name !== 'home') {
      return;
    }
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach(e => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        this.$Api.promotion.GetHomeSeoData().then(result => {
          document.title = result.Data.Title;
        });
      });
    }
  }
  beforeCreate() {
    if (
      ((Vue.prototype.userAgent === 'mobile' && FrontE.mobileBuilding) ||
        (Vue.prototype.userAgent === 'pc' && FrontE.pcBuilding)) &&
      this.$route.name !== 'building'
    ) {
      this.$router.push('/building');
    }
    if (!window['MemberApi']) window['MemberApi'] = this.$Api.member;
  }
}
